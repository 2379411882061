import React, { useEffect, useState, Suspense } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateLayout } from '../redux/actions'
import ThemeCustomizer from '../components/ThemeCustomizer'

const LeftSidebar = React.lazy(() => import('../components/LeftSidebar'))
const Topbar = React.lazy(() => import('../components/Topbar'))
const RightSidebar = React.lazy(() => import('../components/RightSidebar'))

const loading = () => <div className="text-center"></div>

function DetachedLayout(props) {
    const { dispatch, children, layout } = props
    const [isMenuOpened, setIsMenuOpened] = useState(false)

    useEffect(() => {
        dispatch(updateLayout({ orientation: 'detached' }))
    }, [])

    const openMenu = (e) => {
        e.preventDefault()
        setIsMenuOpened(!isMenuOpened)
        if (document.body) document.body.classList.add('sidebar-enable')
    }

    const isCondensed = layout.leftSideBar == 'condensed'
    const isLight = layout.theme == 'light'

    return (
        <div className="app">
            <Suspense fallback={loading()}>
                <Topbar
                    {...props}
                    isMenuOpened={isMenuOpened}
                    openMenuCallback={openMenu}
                    navCssClasses="topnav-navbar topnav-navbar-dark"
                />
            </Suspense>
            <Container fluid>
                <div className="wrapper">
                    <Suspense fallback={loading()}>
                        <LeftSidebar
                            {...props}
                            isMenuOpened={isMenuOpened}
                            isCondensed={isCondensed}
                            isLight={isLight}
                            hideLogo={true}
                        />
                    </Suspense>

                    <div className="content-page">
                        <div className="content">
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </div>
                    </div>
                </div>
            </Container>
            <Suspense fallback={loading()}>
                <RightSidebar {...props}>
                    <ThemeCustomizer />
                </RightSidebar>
            </Suspense>
        </div>
    )
}

export default connect((store) => ({
    layout: store.layout,
}))(DetachedLayout)
