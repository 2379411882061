export default ({ existing, payload }) => {
    console.log('existing, payload', existing, payload)
    if (!payload.length) return existing

    payload.map(d => {
        const index = existing.findIndex(d2 => d2._id == d._id)

        if (index == -1) existing.push(d)
        else existing[`${index}`] = d
    })

    return existing
}
