let uuid = 1
const assignIdAndParent = (menuItems, parentId) => {
    menuItems = menuItems || []
    menuItems.forEach((item) => {
        const id = item.id || uuid
        uuid += 1
        item.id = id
        item.parentId = item.parentId || parentId
        item.active = false

        if (typeof item.children !== 'undefined') {
            assignIdAndParent(item.children, id)
        }
    })

    return menuItems
}

export const assignIds = (menuItems) => assignIdAndParent(menuItems)

export const getActivatedMenuItemIds = (menuItems) => {
    var matchingItems = []
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(menuItem.path) === 0) {
            matchingItems.push(menuItem.id)
        }

        if (typeof menuItem.children !== 'undefined') {
            matchingItems = [
                ...matchingItems,
                ...getActivatedMenuItemIds(menuItem.children),
            ]
        }
    }
    return matchingItems
}

export const manageBodyClass = (cssClass, action = 'toggle') => {
    switch (action) {
        case 'add':
            if (document.body) document.body.classList.add(cssClass)
            break
        case 'remove':
            if (document.body) document.body.classList.remove(cssClass)
            break
        default:
            if (document.body) document.body.classList.toggle(cssClass)
            break
    }

    return true
}
export const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value)
    return true
}
