export default ({ products, organization }) => {
    const hydratedProducts = []

    for (let product of products) {
        const orgProduct = organization.products.filter(
            p2 => p2.productId == `${product._id}`
        )[0]

        hydratedProducts.push({ ...product, ...orgProduct })
    }

    return hydratedProducts
}
