import React, { Component } from 'react'
import { withRouter, Switch, Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

// import { allFlattenRoutes as routes } from './index'
import { authenticatedRoutes, unauthenticatedRoutes } from './index'
import * as layoutConstants from '../constants/layout'
import store from '../redux'

// All layouts/containers
import AuthLayout from '../layouts/Auth'
import VerticalLayout from '../layouts/Vertical'
import HorizontalLayout from '../layouts/Horizontal'
import DetachedLayout from '../layouts/Detached'

const PrivateRoute = ({
    user,
    roles,
    component: Component,
    permissionKey,
    ...rest
}) => {
    // const { user, roles } = store.getState()
    // legacy compatibility
    if (!roles) return <Redirect to={{ pathname: '/account/login' }} />

    const role = roles.filter((r) => r._id == user.roleId)[0]

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!user) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname: '/account/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                }

                // TODO: get user roles
                // const loggedInUser = getLoggedInUser()
                // // check if route is restricted by role

                if (role.permissions[permissionKey] == 0) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: '/' }} />
                }

                // authorised so return component
                return <Component {...props} />
            }}
        />
    )
}

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = []

    routes = routes || []
    routes.map((item) => {
        flatRoutes.push(item)

        if (typeof item.children !== 'undefined')
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    })
    return flatRoutes
}
const flattenedAuthRoutes = flattenRoutes(authenticatedRoutes)
const flattedUnauthRoutes = flattenRoutes(unauthenticatedRoutes)

function Routes(props) {
    const { history, layout, user } = props

    history.listen(() => window.scrollTo(0, 0))

    const getLayout = () => {
        if (!user) return AuthLayout

        let layoutCls = VerticalLayout

        switch (layout.orientation) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout
                break
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout
                break
            default:
                layoutCls = VerticalLayout
                break
        }
        return layoutCls
    }

    const Layout = getLayout()

    // console.log('props',props)

    return (
        <Layout {...props}>
            <Switch>
                {flattenedAuthRoutes.map(
                    (route, index) =>
                        !route.children && (
                            <PrivateRoute
                                key={index}
                                exact={true}
                                {...route}
                                {...props}
                            />
                        )
                )}
                {flattedUnauthRoutes.map(
                    (route, index) =>
                        !route.children && (
                            <Route key={index} exact={true} {...route} />
                        )
                )}
                {/* Fall through */}
                <Redirect exact to="/" />
            </Switch>
        </Layout>
    )
}
// export default withRouter(Routes)
export default withRouter(
    connect((store) => ({
        layout: store.layout,
        user: store.user,
        roles: store.roles,
    }))(Routes)
)
