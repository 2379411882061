import React, { useEffect, Suspense } from 'react'
import { connect } from 'react-redux'

const loading = () => <div className="text-center"></div>

function DefaultLayout({ children }) {
    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg')

        return function cleanup() {
            if (document.body)
                document.body.classList.remove('authentication-bg')
        }
    }, [])

    return <Suspense fallback={loading()}>{children}</Suspense>
}

export default connect()(DefaultLayout)
