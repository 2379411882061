import merge from 'deepmerge'
import moment from 'moment'

import * as action from './actions'
import { updateArray } from '../utils'
import { manageBodyClass, changeBodyAttribute } from './utils'

export const appMenuReducer = (state = {}, { type, payload }) => {
    switch (type) {
        // case action.INIT_MENU:
        //     return payload
        case action.INIT_MENU_SUCCESS:
            return { ...state, ...payload }
        case action.CHANGE_ACTIVE_MENU_FROM_LOCATION:
            return { ...state }
        case action.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
            return { ...state, ...payload }
        default:
            return state
    }
}

export const accessTokenReducer = (state = false, { type, payload }) => {
    switch (type) {
        case action.SET_ACCESS_TOKEN:
            return payload
        default:
            return state
    }
}
export const refreshTokenReducer = (state = false, { type, payload }) => {
    switch (type) {
        case action.SET_REFRESH_TOKEN:
            return payload
        default:
            return state
    }
}

export const userReducer = (state = false, { type, payload }) => {
    switch (type) {
        case action.USER_SET:
            return payload
        case action.USER_UPDATE:
            return {
                ...state,
                ...payload,
            }
        case action.USER_LOG_OUT:
            return false
        default:
            return state
    }
}

export const usersReducer = (state = false, { type, payload }) => {
    switch (type) {
        case action.USERS_SET:
            return payload
        default:
            return state
    }
}

export const organizationReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case action.ORGANIZATION_SET:
            return payload
        case action.ORGANIZATION_UPDATE:
            return { ...state, ...payload }
        case action.USERS_UPDATE:
            return { ...state, ...payload }
        default:
            return state
    }
}

export const rolesReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.ROLES_SET:
            return payload
        default:
            return state
    }
}

export const productsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_PRODUCTS:
            return payload
        case action.UPDATE_SINGLE_PRODUCT:
            // console.log('updating single obj', payload);
            return state.map((d) =>
                d._id == payload._id ? { ...d, ...payload } : d
            )
        case action.UPDATE_SINGLE_PRODUCT_CRAWL:
            return state.map((d) =>
                d._id == payload._id ? { ...d, ...payload.toUpdate } : d
            )
        default:
            return state
    }
}

export const competitorProductsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_COMPETITOR_PRODUCTS:
            return payload
        case action.UPDATE_SINGLE_COMPETITOR_PRODUCT:
            // console.log('updating single obj', payload);
            return state.map((d) =>
                d._id == payload._id ? { ...d, ...payload } : d
            )
        case action.UPDATE_SINGLE_COMPETITOR_PRODUCT_CRAWL:
            return state.map((d) =>
                d._id == payload._id
                    ? { ...d, [payload.channel]: payload.toUpdate }
                    : d
            )
        default:
            return state
    }
}

// export const usersReducer = (state = [], { type, payload }) => {
// 	switch (type) {
// 	case action.USERS_UPDATE:
// 		return [...payload];
// 	default:
// 		return state;
// 	}
// };

export const themeReducer = (
    state = {
        mode: 'light',
        tooltip: 'dark',
    },
    { type, payload }
) => {
    switch (type) {
        case action.UPDATE_THEME:
            return payload
        default:
            return state
    }
}

export const systemReducer = (
    state = {
        allSystemsColor: undefined,
        online: false,
        connection: 'connected',
        selectedChannel: null,
        showCompetitors: false,
        productKey: 'products',
        startDate: moment().subtract(30, 'days').startOf('day').format(),
        endDate: moment().endOf('day').format(),
    },
    { type, payload }
) => {
    switch (type) {
        case action.UPDATE_SYSTEM:
            return { ...state, ...payload }
        default:
            return state
    }
}

export const filtersReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_FILTERS:
            return { ...payload }
        default:
            return state
    }
}

export const channelsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_CHANNELS:
        case action.UPDATE_CHANNELS:
            return payload
        default:
            return state
    }
}

export const orgKeywordsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_ORG_KEYWORDS:
            return payload
        default:
            return state
    }
}

export const keywordsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_KEYWORDS:
            return payload
        case action.UPDATE_KEYWORDS:
            return payload
        case action.UPDATE_SINGLE_KEYWORD:
            return state.map((d) =>
                d._id == payload._id
                    ? { ...d, pages: { ...d.pages, ...payload.toUpdate } }
                    : d
            )
        default:
            return state
    }
}

export const modulesReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_MODULES:
        case action.UPDATE_MODULES:
            return payload
        default:
            return state
    }
}

export const notificationsReducer = (
    state = [],
    { type, key, notification }
) => {
    switch (type) {
        case 'ENQUEUE_SNACKBAR':
            return [...state, notification]

        case 'REMOVE_SNACKBAR':
            return state.filter((n) => n.key !== key)

        default:
            return state
    }
}

export const feedsReducer = (state = {}, { type, payload }) => {
    // console.log('update coming in', type, payload, state);
    switch (type) {
        case 'SET_FEEDS':
            return payload
        case 'UPDATE_FEEDS':
            if (state[`${payload.key}`].length > 30) {
                state[`${payload.key}`].shift()
            }
            return { ...state, [`${payload.key}`]: [...payload.data] }

        default:
            return state
    }
}

export const widgetBoardReducer = (
    state = {
        availableWidgets: [
            'Variances',
            'Deals',
            'Price',
            'Rating',
            'RatingUnder',
            'Reviews',
            'Content',
            'BuyBox',
            'Suppressions',
            'Issues',
            'OOS',
        ],
        activeWidgets: [],
    },
    { type, payload }
) => {
    switch (type) {
        case action.SET_WIDGET_BOARD:
        case action.UPDATE_WIDGET_BOARD:
            return payload
        default:
            return state
    }
}

export const keywordCategoriesReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_KEYWORD_CATEGORIES:
        case action.UPDATE_KEYWORD_CATEGORIES:
            return payload
        default:
            return state
    }
}

// export const orgLogsReducer = (state = [], { type, payload }) => {
//     switch (type) {
//         case action.SET_ORGLOGS:
//             return payload
//         case action.UPDATE_ORGLOG:
//             return [payload, ...state]
//         default:
//             return state
//     }
// }

export const agencyReducer = (state = [], { type, payload }) => {
    switch (type) {
        case action.SET_AGENCY:
            return payload
        case action.UPDATE_AGENCY:
            return [payload, ...state]
        default:
            return state
    }
}

export const layoutReducer = (
    state = {
        orientation: 'vertical', // horizontal (topnav), detached
        width: 'fluid', // boxed
        appMenuTheme: 'default', // light, dark
        appMenuMode: 'condensed', // fixed, scrollable
        showRightSidebar: false, // true
    },
    { type, payload }
) => {
    switch (type) {
        case action.UPDATE_LAYOUT:
            const updatedState = { ...state, ...payload }

            changeBodyAttribute('data-layout', updatedState.layout)
            changeBodyAttribute('data-layout-mode', updatedState.width)
            changeBodyAttribute('data-leftbar-theme', updatedState.appMenuTheme)
            changeBodyAttribute(
                'data-leftbar-compact-mode',
                updatedState.appMenuMode
            )
            return { ...state, ...payload }
        default:
            return state
    }
}
