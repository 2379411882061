import axios from 'axios'

import { store } from '../redux'

import { updateAxios } from '../redux/actions'
import { handleGenerateCancelToken, handleRefreshToken } from '.'

export default async function () {
    axios.interceptors.request.use(
        (config) => {
            if (
                (!config.url.includes(process.env.API_URL) &&
                    !config.url.includes('https')) ||
                (!config.url.includes(process.env.API_URL) &&
                    !config.url.includes('http'))
            ) {
                config.url = `${process.env.API_URL}${config.url}`
            }

            if (store.getState().accessToken) {
                config.headers['Authorization'] = `Bearer ${
                    store.getState().accessToken
                }`
                config.headers['Access-Control-Allow-Origin'] = '*'
            }

            // Attach cancellation token globally
            if (
                store.axios &&
                store.axios.cancel != undefined &&
                store.axios.token != undefined
            ) {
                config.cancelToken = store.axios.token
            }

            console.log('outgoing request', config)

            return config
        },
        (err) => {
            if (!err) return

            console.log('error in axiosInterceptors', err)
            return Promise.reject(err)
        }
    )

    let isRefreshing = false
    let subscribers = []

    axios.interceptors.response.use(
        async (response) => {
            console.log('incoming response', response)

            // if (!response.data) return Promise.reject(response);
            // Do something with response data
            return response
        },
        async (err) => {
            console.log('error in Axios Interceptors')
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error response')
                console.log(err.response.data)
                console.log(err.response.status)
                console.log(err.response.headers)
            } else if (err.request) {
                // The request was made but no response was received
                // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('error request')
                console.log(err.request)
            } else {
                console.log('out of bounds error', err.message)
            }

            if (err.response && err.response.status === 401) {
                const originalRequest = err.config
                console.log('originalRequest', originalRequest)

                if (!isRefreshing) {
                    isRefreshing = true

                    handleRefreshToken().then((token) => {
                        isRefreshing = false
                        onRrefreshed(token)
                        subscribers = []
                    })
                }

                const requestSubscribers = new Promise((resolve) => {
                    subscribeTokenRefresh((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`
                        resolve(axios(originalRequest))
                    })
                })

                return requestSubscribers
            }

            return Promise.reject(err)
        }
    )

    const subscribeTokenRefresh = (cb) => subscribers.push(cb)
    const onRrefreshed = (token) => subscribers.map((cb) => cb(token))
}
