import moment from 'moment'

export default ({ data, channels, system, t }) => {
    const omniSalesChart = []
    const channelSalesChart = []

    let days = []
    let start = moment(new Date(system.startDate))
    let end = moment(new Date(system.endDate))
    for (let current = start; current.isSameOrBefore(end); current.add(1, 'd'))
        days.push(current.format('L'))

    channels.map((channel) => {
        const channelData = data.filter((d) => d.channelId == channel._id)

        const sales = []
        const unitsSold = []
        days.map((day) => {
            const channelDay = channelData.filter((d) =>
                moment(d.date).isSame(day, 'day')
            )[0]

            if (!channelDay) {
                sales.push(0)
                unitsSold.push(0)
                return
            }

            if (channelDay.sales == null || parseFloat(channelDay.sales) < 0)
                sales.push(0)
            else sales.push(channelDay.sales)

            if (
                channelDay.unitsSold == null ||
                parseFloat(channelDay.unitsSold) < 0
            )
                unitsSold.push(0)
            else unitsSold.push(channelDay.unitsSold)
        })

        const obj = {
            name: `${t(`retailer.${channel.retailer}`)} ${channel.brand}`,
            // data: chartView == 'sales' ? sales : unitsSold,
            sales,
            unitsSold,
        }

        channelSalesChart.push({
            numSales: sales.length ? sales.slice(-1)[0] : 0,
            numUnits: unitsSold.length ? unitsSold.slice(-1)[0] : 0,
            country: channel.country,
            ...obj,
        })

        omniSalesChart.push(obj)
    })

    return {
        omniSalesChart,
        channelSalesChart,
        days,
    }
}
