import axios from 'axios'
import { Base64 } from 'js-base64'

import { store } from '../redux'
import { setAccessToken } from '../redux/actions'

export default async function() {
    const token = store.getState().refreshToken

    if (!token) return

    const encoded = Base64.encodeURI(token)

    // Get new access token
    const r = await axios(`refreshToken/${encoded}`)

    if (!r || r.status !== 400) await store.dispatch(setAccessToken(r.data))

    return r.data
}
