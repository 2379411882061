import React from 'react'
import { Translation } from 'react-i18next'

import { routesForNavigationOnly as routes } from '../routes'
import { assignIds, getActivatedMenuItemIds, manageBodyClass } from './utils'
import { updateArray } from '../utils'
import { store } from '.'

// APP MENU
// export const INIT_MENU = 'INIT_MENU'
export const INIT_MENU_SUCCESS = 'INIT_MENU_SUCCESS'
export const CHANGE_ACTIVE_MENU_FROM_LOCATION =
    'CHANGE_ACTIVE_MENU_FROM_LOCATION'
export const CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS =
    'CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS'
export const initAppMenu = () => (dispatch) => {
    try {
        console.log('routes', routes)
        const menuItems = assignIds(routes)

        console.log('menuItems', menuItems)

        dispatch({
            type: INIT_MENU_SUCCESS,
            payload: { menuItems },
        })
    } catch (error) {}
}

export const changeActiveMenuFromLocation = () => (dispatch) => {
    try {
        const menuItems = assignIds(routes)
        const activatedMenuItemIds = getActivatedMenuItemIds(menuItems)

        dispatch({
            type: CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
            payload: { activatedMenuItemIds },
        })
    } catch (error) {}
}

// USER
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const setAccessToken = (payload) => ({ type: SET_ACCESS_TOKEN, payload })
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const setRefreshToken = (payload) => ({
    type: SET_REFRESH_TOKEN,
    payload,
})

// USER
export const USER_SET = 'USER_SET'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_LOG_OUT = 'USER_LOG_OUT'
export const setUser = (payload) => ({ type: USER_SET, payload })
export const updateUser = (payload) => ({ type: USER_UPDATE, payload })
export const logout = (payload) => (dispatch) => {
    dispatch({ type: USER_LOG_OUT })
}

// ORGANIZATION
export const ORGANIZATION_SET = 'ORGANIZATION_SET'
export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE'
export const setOrganization = (payload) => ({
    type: ORGANIZATION_SET,
    payload,
})
export const updateOrganization = (payload) => ({
    type: ORGANIZATION_UPDATE,
    payload,
})

// ROLES
export const ROLES_SET = 'ROLES_SET'
export const setRoles = (payload) => ({
    type: ROLES_SET,
    payload,
})
export const updateRoles = (payload) => (dispatch) => {
    const { roles } = store.getState()

    payload.map((r) => {
        const index = roles.findIndex((d) => d._id == r._id)

        if (~index) roles[index] = { ...roles[index], ...r }
        else roles.push(r)
    })

    dispatch({
        type: ROLES_SET,
        payload: roles,
    })
}

// USERS
export const USERS_SET = 'USERS_SET'
export const USERS_UPDATE = 'USERS_UPDATE'
export const setUsers = (payload) => ({ type: USERS_SET, payload })
export const updateUsers = (payload) => (dispatch) => {
    const { users } = store.getState()
    const index = users.findIndex((u) => u._id == payload._id)

    users[index] = { ...users[index], ...payload }

    dispatch({
        type: USERS_SET,
        payload: users,
    })
}

// THEME actions
export const UPDATE_THEME = 'UPDATE_THEME'
export const updateTheme = (payload) => ({ type: UPDATE_THEME, payload })

// SYSTEM actions
export const UPDATE_SYSTEM = 'UPDATE_SYSTEM'
export const updateSystem = (payload) => ({ type: UPDATE_SYSTEM, payload })

// CHANNELS actions
export const SET_CHANNELS = 'SET_CHANNELS'
export const UPDATE_CHANNELS = 'UPDATE_CHANNELS'
export const setChannels = (payload) => ({ type: SET_CHANNELS, payload })
export const updateChannels = (payload) => (dispatch) => {
    const updated = [...store.getState().channels]
    const index = updated.findIndex((c) => c._id == payload._id)

    if (index == -1) updated.push(payload)
    else updated[`${index}`] = { ...updated[`${index}`], ...payload }

    console.log('index and updated', index, updated)

    dispatch({ type: UPDATE_CHANNELS, payload: updated })
}

// PRODUCTS actions
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const UPDATE_SINGLE_PRODUCT = 'UPDATE_SINGLE_PRODUCT'
export const UPDATE_SINGLE_PRODUCT_CRAWL = 'UPDATE_SINGLE_PRODUCT_CRAWL'
export const setProducts = (payload) => ({ type: SET_PRODUCTS, payload })
export const updateSingleProduct = (payload) => ({
    type: UPDATE_SINGLE_PRODUCT,
    payload,
})
export const updateSingleProductCrawl = (payload) => ({
    type: UPDATE_SINGLE_PRODUCT_CRAWL,
    payload,
})

// COMPETITOR PRODUCTS actions
export const SET_COMPETITOR_PRODUCTS = 'SET_COMPETITOR_PRODUCTS'
export const UPDATE_SINGLE_COMPETITOR_PRODUCT =
    'UPDATE_SINGLE_COMPETITOR_PRODUCT'
export const UPDATE_SINGLE_COMPETITOR_PRODUCT_CRAWL =
    'UPDATE_SINGLE_COMPETITOR_PRODUCT_CRAWL'
export const setCompetitorProducts = (payload) => ({
    type: SET_COMPETITOR_PRODUCTS,
    payload,
})
export const updateSingleCompetitorProduct = (payload) => ({
    type: UPDATE_SINGLE_COMPETITOR_PRODUCT,
    payload,
})
export const updateSingleCompetitorProductCrawl = (payload) => ({
    type: UPDATE_SINGLE_COMPETITOR_PRODUCT_CRAWL,
    payload,
})

// ALERT FILTERS actions
export const SET_FILTERS = 'SET_FILTERS'
export const setFilters = (payload) => ({ type: SET_FILTERS, payload })

// KEYWORDS actions
export const SET_ORG_KEYWORDS = 'SET_ORG_KEYWORDS'
export const setOrgKeywords = (payload) => ({ type: SET_ORG_KEYWORDS, payload })
export const updateOrgKeywords = (payload) => (dispatch) => {
    const updated = updateArray({
        existing: [...store.getState().orgKeywords],
        payload,
    })

    dispatch({ type: SET_ORG_KEYWORDS, payload: updated })
}

// KEYWORDS actions
export const SET_KEYWORDS = 'SET_KEYWORDS'
export const UPDATE_KEYWORDS = 'UPDATE_KEYWORDS'
export const UPDATE_SINGLE_KEYWORD = 'UPDATE_SINGLE_KEYWORD'
export const setKeywords = (payload) => ({ type: SET_KEYWORDS, payload })
export const updateKeywords = (payload) => (dispatch) => {
    const updated = updateArray({
        existing: [...store.getState().keywords],
        payload,
    })

    dispatch({ type: UPDATE_KEYWORDS, payload: updated })
}
export const updateSingleKeyword = (payload) => ({
    type: UPDATE_SINGLE_KEYWORD,
    payload,
})

// MODULE actions
export const SET_MODULES = 'SET_MODULES'
export const UPDATE_MODULES = 'UPDATE_MODULES'
export const setModules = (payload) => ({ type: SET_MODULES, payload })
export const updateModules = (payload) => (dispatch) => {
    const updated = updateArray({
        existing: [...store.getState().modules],
        payload,
    })

    dispatch({ type: UPDATE_MODULES, payload: updated })
}

export const enqueueSnackbar = ({ k, t, m, v }) => (dispatch) => {
    let variant = 'error'

    if (t == 'success') variant = 'success'

    const notification = {
        message: m || (
            <Translation>
                {(t, { i18n }) => <p>{t(`${variant}:${k}`)}</p>}
            </Translation>
        ),
        options: { variant: v || 'error' },
    }

    dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
            key: new Date().getTime() + Math.random(),
            ...notification,
        },
    })
}

export const removeSnackbar = (key) => ({
    type: 'REMOVE_SNACKBAR',
    key,
})

// MODULE actions
export const SET_FEEDS = 'SET_FEEDS'
export const UPDATE_FEEDS = 'UPDATE_FEEDS'
export const setFeeds = (payload) => ({ type: SET_FEEDS, payload })
export const updateFeeds = (payload) => (dispatch) => {
    const updated = updateArray({
        existing: [...store.getState().modules],
        payload,
    })

    dispatch({ type: UPDATE_FEEDS, payload: updated })
}

// WIDGET BOARD actions
export const SET_WIDGET_BOARD = 'SET_WIDGET_BOARD'
export const UPDATE_WIDGET_BOARD = 'UPDATE_WIDGET_BOARD'
export const setWidgetBoard = (payload) => ({ type: SET_WIDGET_BOARD, payload })
export const updateWidgetBoard = (payload) => (dispatch) => {
    // const updated = updateArray({
    // 	existing: [...store.getState().widgetBoard],
    // 	payload,
    // });

    const updated = {
        ...store.getState().widgetBoard,
        ...payload,
    }

    dispatch({ type: UPDATE_WIDGET_BOARD, payload: updated })
}

// KEYWORD CATEGORIES actions
export const SET_KEYWORD_CATEGORIES = 'SET_KEYWORD_CATEGORIES'
export const UPDATE_KEYWORD_CATEGORIES = 'UPDATE_KEYWORD_CATEGORIES'
export const setKeywordCategories = (payload) => ({
    type: SET_KEYWORD_CATEGORIES,
    payload,
})
export const updateKeywordCategories = (payload) => (dispatch) => {
    console.log('payload', payload)
    dispatch({
        type: UPDATE_KEYWORD_CATEGORIES,
        payload: updateArray({
            existing: [...store.getState().keywordCategories],
            payload,
        }),
    })
}

// ORG LOGS actions
// export const SET_ORGLOGS = 'SET_ORGLOGS'
// export const UPDATE_ORGLOG = 'UPDATE_ORGLOG'
// export const setOrgLogs = (payload) => ({ type: SET_ORGLOGS, payload })
// export const updateOrgLog = (payload) => ({
//     type: UPDATE_ORGLOG,
//     payload,
// })

// AGENCY actions
export const SET_AGENCY = 'SET_AGENCY'
export const UPDATE_AGENCY = 'UPDATE_AGENCY'
export const setAgency = (payload) => ({ type: SET_AGENCY, payload })
export const updateAgency = (payload) => ({
    type: UPDATE_AGENCY,
    payload,
})

// RIGHT SIDE BAR actions
export const UPDATE_LAYOUT = 'UPDATE_LAYOUT'
export const updateLayout = (payload) => ({ type: UPDATE_LAYOUT, payload })

export const showRightSidebar = (payload) => (dispatch) => {
    manageBodyClass('right-bar-enabled', 'add')

    dispatch({ type: UPDATE_LAYOUT, payload: { showRightSidebar: true } })
}
export const hideRightSidebar = (payload) => (dispatch) => {
    manageBodyClass('right-bar-enabled', 'remove')

    dispatch({
        type: UPDATE_LAYOUT,
        payload: { showRightSidebar: false },
    })
}
