import { store, actions } from '../redux';
import { updateArray } from '.';

export default function(payload, existing = [...store.getState().products]) {
	const updated = updateArray({
		existing,
		payload,
	});

	if (!updated.length) return;

	console.log('has something to update', updated.length);
	// const filtered = [];
	// const channels = store.getState().channels;

	// updated.map((p) => {
	// 	const c = channels.filter((c2) => c2._id == p.channelId)[0];
	// 	if (!c) return;

	// 	filtered.push({ ...p, channelName: c.name });
	// });

	console.log('updated', updated);
	store.dispatch(actions.setProducts(updated));
}
