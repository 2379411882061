import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import moment from 'moment'

import './src/i18n'
import Routes from './src/routes/Routes'
import { history } from './src/utils'

import './src/assets/scss/reset.css'
import 'react-toastify/dist/ReactToastify.css'

// Themes
// import './src/assets/scss/Saas.scss'

// For Dark import Saas-Dark.scss
// import './src/assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
import './src/assets/scss/Modern.scss'
// For modern dakr import Modern-Dark.scss
// import './src/assets/scss/Modern-Dark.scss'

// For Creative demo import Modern.scss
// import './src/assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './src/assets/scss/Creative-Dark.scss';

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '%d s',
        ss: '%d s',
        m: '%d m',
        mm: '%d m',
        h: '%d h',
        hh: '%d h',
        d: '%d d',
        dd: '%d d',
        M: 'a mth',
        MM: '%d mths',
        y: 'y',
        yy: '%d y',
    },
})

export default function App() {
    return (
        <Router history={history}>
            <Routes />
        </Router>
    )
}
