import { mergeKeywordPageAndProduct } from './utilityFunctions'

export default ({ keyword }) => {
    const hydrated = mergeKeywordPageAndProduct({ keyword })

    const hasMatches = hydrated.numProducts ? true : false

    return { ...hydrated, hasMatches }
}

// import { mergeKeywordPageAndProduct } from './utilityFunctions'

// export default ({ keyword }) => {
//     if (!keyword.pages) return keyword

//     const hydratedKeyword = { ...keyword, hasMatches: false, pages: {} }

//     for (const [pageNum, page] of Object.entries(keyword.pages)) {
//         const hydrated = mergeKeywordPageAndProduct({ page })

//         hydratedKeyword.pages[pageNum] = hydrated

//         if (hydratedKeyword.pages[pageNum].numProducts)
//             hydratedKeyword.hasMatches = true
//     }

//     return hydratedKeyword
// }
