import axios from 'axios';

export default function() {
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();

	source.token.throwIfRequested = source.token.throwIfRequested;
	source.token.promise.then = source.token.promise.then.bind(
		source.token.promise
	);
	source.token.promise.catch = source.token.promise.catch.bind(
		source.token.promise
	);

	return source;
}
