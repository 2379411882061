import React from 'react'

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'))
const PasswordReset = React.lazy(() => import('../pages/auth/PasswordReset'))
// const Confirm = React.lazy(() => import('../pages/auth/Confirm'))

//Omni Channel
const OmniChannelDashboard = React.lazy(() =>
    import('../pages/omniChannelDashboard/index')
)
const OmniIssues = React.lazy(() => import('../pages/omniIssues'))

//Channel
const ChannelBuyBox = React.lazy(() => import('../pages/channelBuyBox/Buybox'))
const ChannelRepricer = React.lazy(() => import('../pages/channelBuyBox/Repricer'))
const ChannelKeywords = React.lazy(() => import('../pages/channelKeywords'))
const ChannelKeywordsManage = React.lazy(() =>
    import('../pages/channelKeywords/manage')
)
const ChannelContent = React.lazy(() => import('../pages/channelContent'))
const ChannelReviews = React.lazy(() => import('../pages/channelReviews'))
const ChannelSettings = React.lazy(() => import('../pages/ChannelSettings'))
const ChannelInventory_CurrentInventory = React.lazy(() =>
    import('../pages/channelInventory/CurrentInventory')
)
const ChannelInventory_InboundShipments = React.lazy(() =>
    import('../pages/channelInventory/InboundShipments')
)
const ChannelInventory_Returns = React.lazy(() =>
    import('../pages/channelInventory/Returns')
)
const ChannelInventory_ReturnsToVendor = React.lazy(() =>
    import('../pages/channelInventory/ReturnToVendor')
)
const ChannelInventory_Disposed = React.lazy(() =>
    import('../pages/channelInventory/Disposed')
)
const ChannelExports = React.lazy(() => import('../pages/channelExports'))

// Organization
const PurchaseOrders = React.lazy(() => import('../pages/purchaseOrders'))

// Behind the scenes pages
const Account = React.lazy(() => import('../pages/account'))
const Organization = React.lazy(() => import('../pages/organization'))
const NewChannel = React.lazy(() => import('../pages/NewChannel'))
const ProductLayout = React.lazy(() => import('../pages/product/index'))
const ProductOmniOverview = React.lazy(() =>
    import('../pages/product/OmniOverview')
)
const ProductChannelOverview = React.lazy(() =>
    import('../pages/product/ChannelOverview')
)
const ProductInventory = React.lazy(() => import('../pages/product/Inventory'))
const ProductReviews = React.lazy(() => import('../pages/product/Reviews'))
const ProductKeywords = React.lazy(() => import('../pages/product/Keywords'))
const AppImports = React.lazy(() => import('../pages/appImports/index'))
const ImportProcessor = React.lazy(() =>
    import('../pages/appImports/ImportProcessor')
)

// fix
// auth
const unauthenticatedRoutes = [
    {
        path: '/account',
        name: 'Auth',
        children: [
            {
                path: '/account/login',
                name: 'Login',
                component: Login,
            },
            {
                path: '/account/logout',
                name: 'Logout',
                component: Logout,
            },
            // {
            //     path: '/account/confirm',
            //     name: 'Confirm',
            //     component: Confirm,
            // },
            {
                path: '/account/forgotpassword',
                name: 'Forgot Password',
                component: ForgetPassword,
            },
            {
                path: '/account/passwordreset/:token',
                name: 'Password Reset',
                component: PasswordReset,
            },
        ],
    },
]

// Omni Channel Routes
const omniChannelRoutes = [
    {
        path: '/omni/dashboard',
        name: 'Dashboard',
        icon: 'uil-graph-bar',
        component: OmniChannelDashboard,
        header: 'Omni Channel',
        permissionKey: 'omniDashboard',
    },
    {
        path: '/omni/issues',
        name: 'Issue Tracker',
        icon: 'uil-calender',
        component: OmniIssues,
        permissionKey: 'omniIssues',
    },
]

// Channel Routes
const channelRoutes = [
    {
        path: '/channel/pricing',
        name: 'Pricing',
        icon: 'uil-usd-square',
        children: [
            {
                path: '/channel/pricing/buybox',
                name: 'Buy Box',
                component: ChannelBuyBox,
                permissionKey: 'channelBuyBox',
            },
            {
                path: '/channel/pricing/repricer',
                name: 'Repricer',
                component: ChannelRepricer,
                permissionKey: 'channelRepricer',
            },
        ],
    },
    {
        path: '/channel/keywords',
        name: 'Keywords',
        icon: 'uil-sort-amount-up',
        children: [
            {
                path: '/channel/keywords/overview',
                name: 'Keywords',
                component: ChannelKeywords,
                permissionKey: 'channelKeywords',
            },
            {
                path: '/channel/keywords/manage',
                name: 'Manage Keywords',
                component: ChannelKeywordsManage,
                permissionKey: 'channelKeywordsManage',
            },
        ],
    },
    {
        path: '/channel/inventory',
        name: 'Inventory',
        icon: 'uil-gold',
        children: [
            {
                path: '/channel/inventory/current',
                name: 'Current Inventory',
                component: ChannelInventory_CurrentInventory,
                permissionKey: 'channelCurrentInventory',
            },
            {
                path: '/channel/inventory/inbound',
                name: 'Inbound Shipments',
                component: ChannelInventory_InboundShipments,
                permissionKey: 'channelInboundInventory',
            },
            {
                path: '/channel/inventory/returns',
                name: 'Returns',
                component: ChannelInventory_Returns,
                permissionKey: 'channelReturnsInventory',
            },
            {
                path: '/channel/inventory/returnstovendor',
                name: 'Returns To Vendor',
                component: ChannelInventory_ReturnsToVendor,
                permissionKey: 'channelReturnsToVendorInventory',
            },
            {
                path: '/channel/inventory/disposed',
                name: 'Disposed',
                component: ChannelInventory_Disposed,
                permissionKey: 'channelDisposedInventory',
            },
        ],
    },
    {
        path: '/channel/content',
        name: 'Content',
        icon: 'uil-align-left',
        component: ChannelContent,
        permissionKey: 'channelContent',
    },
    {
        path: '/channel/reviews',
        name: 'Reviews',
        icon: 'uil-star',
        component: ChannelReviews,
        permissionKey: 'channelReviews',
    },
    {
        path: '/channel/exports',
        name: 'Exports',
        component: ChannelExports,
        icon: 'uil-export',
        permissionKey: 'channelExports',
    },
    {
        path: '/channel/settings',
        name: 'Settings',
        icon: 'uil-cog',
        component: ChannelSettings,
        permissionKey: 'channelSettings',
    },
]

// Organization Routes
const organizationRoutes = [
    {
        path: '/organization/purchaseorders',
        name: 'Purchase Orders',
        component: PurchaseOrders,

        icon: ' uil-file-blank',
        header: 'Organization',
        permissionKey: 'purchaseOrders',
    },
]

// Behind the scenes routes
const behindTheScenesRoutes = [
    {
        path: '/',
        component: OmniChannelDashboard,
        permissionKey: 'omniDashboard',
    },
    {
        path: '/account',
        component: Account,
    },
    {
        path: '/organization',
        component: Organization,
        permissionKey: 'myOrgUsers',
    },
    {
        path: '/channel/new',
        component: NewChannel,
    },
    {
        path: '/imports',
        component: AppImports,
    },
    {
        path: '/import/:type',
        component: ImportProcessor,
    },
    // {
    //     path: '/product/:channelId/:id',
    //     component: Product,
    // },
    // TODO: need a better way to do sub layouts
    {
        path: '/product/:channelId/:id/omni',
        component: () => (
            <ProductLayout>
                <ProductOmniOverview />
            </ProductLayout>
        ),
    },
    {
        path: '/product/:channelId/:id/channel',
        component: () => (
            <ProductLayout>
                <ProductChannelOverview />
            </ProductLayout>
        ),
    },
    {
        path: '/product/:channelId/:id/inventory',
        component: () => (
            <ProductLayout>
                <ProductInventory />
            </ProductLayout>
        ),
    },
    {
        path: '/product/:channelId/:id/reviews',
        component: () => (
            <ProductLayout>
                <ProductReviews />
            </ProductLayout>
        ),
    },
    {
        path: '/product/:channelId/:id/keywords',
        component: () => (
            <ProductLayout>
                <ProductKeywords />
            </ProductLayout>
        ),
    },
]

// All routes
const authenticatedRoutes = [
    ...omniChannelRoutes,
    ...channelRoutes,
    ...organizationRoutes,
    ...behindTheScenesRoutes,
]

const routesForNavigationOnly = [
    ...omniChannelRoutes,
    ...channelRoutes,
    ...organizationRoutes,
]

export { routesForNavigationOnly, authenticatedRoutes, unauthenticatedRoutes }
