import moment from 'moment'

export default (props) => {
    let { key, value } = props
    key = key.toLowerCase()
    if (!value || value == '' || value == null) return

    // Targetted parsing first
    if (key == 'price') return `$${value}`
    if (key == 'percentage') return `${value}%`

    if (typeof value === 'string') {
        if (key.includes('date') || key.includes('updated'))
            return moment(value).format('LL')
    }

    if (typeof value === 'boolean') {
        return value ? 'Yes' : ''
    }

    return value
}
