import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateLayout, hideRightSidebar } from '../redux/actions'

function ThemeCustomizer({ dispatch, layout }) {
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false)
    const [isDetachedLayout, setIsDetachedLayout] = useState(false)
    const [isBoxed, setIsBoxed] = useState(false)
    const [isSidebarScrollable, setIsSidebarScrollable] = useState(false)
    const [isCondensed, setIsCondensed] = useState(false)
    const [isLight, setIsLight] = useState(false)
    const [isDark, setIsDark] = useState(false)

    const changeLayout = (e) => {
        switch (e.currentTarget.value) {
            case 'horizontal':
                setIsHorizontalLayout(!isHorizontalLayout)
                setIsCondensed(false)
                setIsDetachedLayout(false)

                dispatch(updateLayout({ orientation: 'topnav' }))
                break
            case 'detached':
                setIsHorizontalLayout(false)
                setIsBoxed(false)
                setIsDetachedLayout(!isDetachedLayout)

                dispatch(updateLayout({ orientation: 'detached' }))
                break
            default:
                setIsHorizontalLayout(false)
                setIsBoxed(false)
                setIsDetachedLayout(false)

                dispatch(updateLayout({ orientation: 'vertical' }))
                break
        }
    }

    const changeWidthMode = (e) => {
        switch (e.currentTarget.value) {
            case 'boxed':
                setIsBoxed(true)
                dispatch(updateLayout({ width: 'boxed' }))
                break
            default:
                setIsBoxed(false)
                dispatch(updateLayout({ width: 'fluid' }))
                break
        }
    }

    const changeTheme = (e) => {
        switch (e.currentTarget.value) {
            case 'light':
                setIsLight(true)
                dispatch(updateLayout({ appMenuTheme: 'light' }))
                break
            case 'dark':
                setIsLight(false)
                setIsDark(true)
                dispatch(updateLayout({ appMenuTheme: 'dark' }))
                break
            default:
                setIsLight(false)
                setIsDark(false)
                dispatch(updateLayout({ appMenuTheme: 'default' }))
                break
        }
    }

    const changeLeftSiderbarType = (e) => {
        switch (e.currentTarget.value) {
            case 'condensed':
                setIsCondensed(true)
                setIsSidebarScrollable(false)
                setIsLight(false)
                setIsDark(false)
                dispatch(updateLayout({ appMenuMode: 'condensed' }))
                break
            case 'scrollable':
                setIsCondensed(false)
                setIsSidebarScrollable(true)
                dispatch(updateLayout({ appMenuMode: 'scrollable' }))
                break
            default:
                setIsCondensed(false)
                setIsSidebarScrollable(false)
                dispatch(updateLayout({ appMenuMode: 'fixed' }))
                break
        }
    }

    return (
        <Fragment>
            <div className="mt-2 p-2">
                <div className="alert alert-primary" role="alert">
                    <strong>Customize the layout, sidebar menu, etc</strong>
                </div>
            </div>

            <h5 className="pl-3">Layout</h5>

            <div className="pl-3">
                <div className="pt-2">
                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="layout"
                            value="vertical"
                            id="vertical-check"
                            onChange={changeLayout}
                            checked={!isHorizontalLayout && !isDetachedLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="vertical-check"
                        >
                            Vertical Layout (Default)
                        </label>
                    </div>

                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="layout"
                            value="horizontal"
                            id="horizontal-check"
                            onChange={changeLayout}
                            checked={isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="horizontal-check"
                        >
                            Horizontal Layout
                        </label>
                    </div>

                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="layout"
                            value="detached"
                            id="detached-check"
                            onChange={changeLayout}
                            checked={isDetachedLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="detached-check"
                        >
                            Detached Layout
                        </label>
                    </div>
                </div>
            </div>

            <hr className="mb-0 mt-2" />
            <h5 className="pl-3">Width</h5>

            <div className="mt-2 pl-3">
                <div className="custom-control custom-switch mb-1">
                    <input
                        type="radio"
                        className="custom-control-input"
                        name="width"
                        value="fluid"
                        id="fluid-check"
                        checked={!isBoxed}
                        onChange={changeWidthMode}
                        disabled={isDetachedLayout}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="fluid-check"
                    >
                        Fluid
                    </label>
                </div>
                <div className="custom-control custom-switch mb-1">
                    <input
                        type="radio"
                        className="custom-control-input"
                        name="width"
                        value="boxed"
                        id="boxed-check"
                        checked={isBoxed}
                        onChange={changeWidthMode}
                        disabled={isDetachedLayout}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="boxed-check"
                    >
                        Boxed
                    </label>
                </div>
            </div>

            <hr className="mb-0" />
            <h5 className="pl-3">Left Sidebar</h5>

            <div className="pl-3">
                <div className="pt-2">
                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="theme"
                            value="default"
                            id="default-theme-check"
                            checked={!isLight && !isDark}
                            onChange={changeTheme}
                            disabled={isDetachedLayout || isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="default-theme-check"
                        >
                            Default
                        </label>
                    </div>

                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="theme"
                            value="light"
                            id="light-theme-check"
                            onChange={changeTheme}
                            checked={isLight}
                            disabled={isDetachedLayout || isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="light-theme-check"
                        >
                            Light
                        </label>
                    </div>

                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="theme"
                            value="dark"
                            id="dark-theme-check"
                            onChange={changeTheme}
                            checked={isDark}
                            disabled={isDetachedLayout || isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="dark-theme-check"
                        >
                            Dark
                        </label>
                    </div>
                </div>
            </div>

            <div className="pl-3">
                <div className="pt-2">
                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="left-sidebar-width"
                            value="fixed"
                            id="left-sidebar-width-fixed"
                            checked={!isCondensed && !isSidebarScrollable}
                            onChange={changeLeftSiderbarType}
                            disabled={isDetachedLayout || isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="left-sidebar-width-fixed"
                        >
                            Fixed (Default)
                        </label>
                    </div>

                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="left-sidebar-width"
                            value="condensed"
                            id="left-sidebar-width-condensed"
                            onChange={changeLeftSiderbarType}
                            checked={isCondensed}
                            disabled={isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="left-sidebar-width-condensed"
                        >
                            Condensed
                        </label>
                    </div>

                    <div className="custom-control custom-switch mb-1">
                        <input
                            type="radio"
                            className="custom-control-input"
                            name="left-sidebar-width"
                            value="scrollable"
                            id="left-sidebar-width-scrollable"
                            onChange={changeLeftSiderbarType}
                            checked={isSidebarScrollable}
                            disabled={isHorizontalLayout}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="left-sidebar-width-scrollable"
                        >
                            Scrollable
                        </label>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect((store) => ({
    layout: store.layout,
}))(ThemeCustomizer)
