import React from 'react'
import moment from 'moment'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import OpenIcon from '@material-ui/icons/OpenInNewOutlined'

import generateDomain from './generateDomain'

const useStyles = makeStyles((theme) => ({
    link: {
        ...theme.flex.flexColumn,
        color: '#9e9e9e',
        fontSize: '0.875rem',
    },
    icon: {
        width: 16,
        height: 16,
        marginLeft: theme.spacing(1) / 2,
    },
}))

export default ({ key, value }) => {
    // console.log('incoming key value pair', key, value)
    const classes = useStyles()
    if (!value || value == '' || value == null) return

    // Targetted parsing first
    if (key == 'variance') return decodeVariance(value)
    if (key == 'price') return `$${value}`
    if (key == 'percentage') return `${value}%`
    if (key == 'id') {
        return (
            <Typography>
                <a
                    href={`https://amazon.com/dp/${value}`}
                    target="_blank"
                    className={classes.link}
                >
                    {value}
                    <OpenIcon className={classes.icon} />
                </a>
            </Typography>
        )
    }

    if (typeof value === 'string') {
        if (
            key.toLowerCase().includes('date') ||
            key.toLowerCase().includes('updated') ||
            key.toLowerCase().includes('last')
        ) {
            return moment(value).fromNow()
        }
        if (key.toLowerCase().includes('created')) {
            return moment(value).format('MMMM D YYYY, hh:mm a')
        }
    }

    if (typeof value === 'boolean') {
        return value ? 'Yes' : ''
    }

    return value
}
