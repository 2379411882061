import * as a from '../redux/actions'
import { pusher } from '.'
import { store } from '../redux'

import { hydrateKeyword } from './'

export default function () {
    if (!store.getState().user) return
    const { dispatch } = store

    const orgId = store.getState().organization._id
    const userId = store.getState().user._id

    console.log('pusher started')

    const orgChannel = pusher.subscribe(orgId)
    const userChannel = pusher.subscribe(userId)
    const sysChannel = pusher.subscribe('system')

    // This will be used primarily for customer support
    // to alter a user account in real time
    // updateChannel.bind('update user', updatedUser => {
    // 	if (state.user._id == updatedUser._id) {
    // 		store.dispatch(updateUser({ ...state.user, ...updatedUser }));
    // 	}
    // });

    /* USER CHANNEL */

    userChannel.bind('new task', (d) => {
        const user = store.getState().user
        
        dispatch(
            a.updateUser({
                numUnreadTasks: user.numUnreadTasks
                    ? user.numUnreadTasks + 1
                    : 1,
            })
        )
    })

    /* ORG CHANNEL */

    orgChannel.bind('update organization', (d) =>
        dispatch(a.updateOrganization(d))
    )
    orgChannel.bind('update users', (d) => dispatch(a.updateUsers(d)))

    orgChannel.bind('update product crawl', async (d) => {
        // console.log('incoming product crawl', d)
        if (!d) return
        dispatch(a.updateSingleProductCrawl(d))
    })

    orgChannel.bind('keyword crawl update', async (d) => {
        // console.log('incoming keyword crawl', d)
        if (!d) return

        const hydrated = hydrateKeyword({ keyword: { ...d.toUpdate } })

        dispatch(
            a.updateSingleKeyword({
                _id: d._id,
                toUpdate: { ...hydrated.pages },
            })
        )
    })

    orgChannel.bind('new org log', (d) => {
        const user = store.getState().user

        dispatch(
            a.updateUser({
                numUnreadNotifications: user.numUnreadNotifications
                    ? user.numUnreadNotifications + 1
                    : 1,
            })
        )
    })

    /* SYSTEM CHANNEL */

    sysChannel.bind('update system color', (d) => {
        // console.log('incoming system status update', d)
        dispatch(a.updateSystem({ allSystemsColor: d }))
    })
}
