import React, { Suspense,useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import * as Sentry from '@sentry/browser'
import { ToastContainer } from 'react-toastify'
import IdleTimer from 'react-idle-timer'

import App from './App'
import { store, persistor } from './src/redux'
import { axiosInterceptors, pusherListeners,userLogout } from './src/utils'

function Root() {
    const idleTimer = useRef(null)

    const PrepareApplication = () => {
        axiosInterceptors()
        pusherListeners()
        Sentry.init({ dsn: process.env.SENTRY_DSN })
    }

    const onIdle = (e) => {
        // if (!process.env.IS_DEV) userLogout()
        // userLogout()
    }

    return (
        <Suspense fallback={null}>
            <IdleTimer
                ref={idleTimer}
                onIdle={onIdle}
                // onAction={onAction}
                timeout={900000} // 15 Minutes
            />
            <Provider store={store}>
                <PersistGate
                    loading={null}
                    onBeforeLift={PrepareApplication}
                    persistor={persistor}
                >
                    <ToastContainer />
                    <App />
                </PersistGate>
            </Provider>
        </Suspense>
    )
}

ReactGA.initialize('UA-116383618-1')
ReactGA.pageview(window.location.pathname + window.location.search)
ReactDOM.render(<Root />, document.getElementById('root'))