import React, { useEffect, Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateLayout } from '../redux/actions'
// import ThemeCustomizer from '../components/ThemeCustomizer'

const LeftSidebar = React.lazy(() => import('../components/LeftSidebar'))
const Topbar = React.lazy(() => import('../components/Topbar'))
// const RightSidebar = React.lazy(() => import('../components/RightSidebar'))

const emptyLoading = () => <div></div>
const loading = () => <div className="text-center"></div>

function VerticalLayout(props) {
    const { dispatch, children, layout } = props
    const openLeftMenu = () => {
        if (document.body) document.body.classList.add('sidebar-enable')
    }

    const updateDimensions = () => {
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            dispatch(updateLayout({ leftSideBar: 'condensed' }))
        } else if (window.innerWidth > 1028) {
            dispatch(updateLayout({ leftSideBar: 'fixed' }))
        }
    }

    const prepare = () => {
        window.addEventListener('resize', updateDimensions)

        // dispatch(updateLayout({ theme: 'dark' }))

        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028)
            dispatch(updateLayout({ leftSideBar: 'condensed' }))
    }

    useEffect(() => {
        prepare()
        return function cleanup() {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const isCondensed = layout.leftSideBar == 'condensed'
    const isLight = layout.theme == 'light'

    return (
        <div className="app">
            <div className="wrapper">
                <Suspense fallback={emptyLoading()}>
                    <LeftSidebar
                        {...props}
                        isCondensed={isCondensed}
                        isLight={isLight}
                        hideUserProfile={true}
                    />
                </Suspense>

                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={emptyLoading()}>
                            <Topbar
                                {...props}
                                openLeftMenuCallBack={openLeftMenu}
                                hideLogo={true}
                            />
                        </Suspense>

                        <Container fluid><Suspense fallback={loading()}>{children}</Suspense></Container>
                    </div>
                </div>
            </div>
            {/* 
            <Suspense fallback={emptyLoading()}>
                <RightSidebar {...props}>
                    <ThemeCustomizer />
                </RightSidebar>
            </Suspense> */}
        </div>
    )
}

export default withRouter(
    connect((store) => ({
        layout: store.layout,
    }))(VerticalLayout)
)
