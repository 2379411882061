import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage'
import localForage from 'localforage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
// import {
// 	loadingBarReducer,
// 	loadingBarMiddleware,
// } from 'react-redux-loading-bar';

import * as reducers from './reducers'
import * as actions from './actions'

const config = {
    key: 'root',
    storage: localForage,
    timeout: null,
    stateReconciler: hardSet,
}

const combinedReducers = combineReducers({
    appMenu: reducers.appMenuReducer,
    user: reducers.userReducer,
    users: reducers.usersReducer,
    organization: reducers.organizationReducer,
    theme: reducers.themeReducer,
    system: reducers.systemReducer,
    products: reducers.productsReducer,
    competitorProducts: reducers.competitorProductsReducer,
    filters: reducers.filtersReducer,
    channels: reducers.channelsReducer,
    keywords: reducers.keywordsReducer,
    notifications: reducers.notificationsReducer,
    // modules: reducers.modulesReducer,
    feeds: reducers.feedsReducer,
    widgetBoard: reducers.widgetBoardReducer,
    keywordCategories: reducers.keywordCategoriesReducer,
    refreshToken: reducers.refreshTokenReducer,
    accessToken: reducers.accessTokenReducer,
    // orgLogs: reducers.orgLogsReducer,
    agency: reducers.agencyReducer,
    layout: reducers.layoutReducer,
    roles: reducers.rolesReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOG_OUT') {
        console.log('clearing redux')

        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        state = undefined
    }
    return combinedReducers(state, action)
}

const persistedReducer = persistReducer(config, rootReducer)

// Un-persisted store for testing purposes
export const JestStore = createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
)

// Persisted Store
const store = createStore(
    persistedReducer,
    undefined,
    compose(applyMiddleware(thunk))
)

const persistor = persistStore(store)

export { persistor, store, actions }

export default store
