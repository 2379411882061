import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en-US',
        fallbackLng: 'en-US',

        ns: ['common', 'form', 'error', 'channel', 'logs'],
        defaultNS: 'common',

        // debug: true,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        react: {
            wait: true,
        },

        backend: {
            loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
        },

        load: 'currentOnly',
        returnObjects: true,
    })

export default i18n
