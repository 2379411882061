import { parseField } from './'

export default ({ data, keys }) => {
    const res = []
    data.map((obj) => {
        const newObj = {}

        if (obj.variance) {
            if (obj.notFound || obj.suppressed || obj.unavailable)
                delete obj.variance
        }

        Object.keys(obj).map((key) => {
            if (key == '__v') return
            if (key == '_id') return
            if (key == 'createdAt') return
            if (key == 'updatedAt') return
            if (key == 'userId') return
            if (key == 'channelId') return

            const value = obj[`${key}`]

            if (keys.includes(key)) {
                if (key == 'bulletPoints') {
                    value.map((bp, i) => {
                        newObj[`Bullet Point ${i + 1}`] = bp
                    })
                    return
                }
                if (key == 'images') {
                    value.map((img, i) => {
                        newObj[`Image ${i + 1}`] = `${
                            process.env.AMAZON_PRODUCT_IMAGE_URL
                        }${img.replace('._SS40_', '')}`
                    })
                    return
                }

                newObj[`${key}`] = parseField({ key, value })
            }
        })

        res.push(newObj)
    })
    return res
}
