import React, { useEffect, useState, Suspense } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateLayout } from '../redux/actions'
import ThemeCustomizer from '../components/ThemeCustomizer'

const Topbar = React.lazy(() => import('../components/Topbar'))
const Navbar = React.lazy(() => import('../components/Navbar'))
const RightSidebar = React.lazy(() => import('../components/RightSidebar'))

const loading = () => <div className="text-center"></div>

function HorizontalLayout(props) {
    const { dispatch, children } = props
    const [isMenuOpened, setIsMenuOpened] = useState(false)

    return (
        <div className="app">
            <div className="wrapper">
                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={loading()}>
                            <Topbar
                                {...props}
                                isMenuOpened={isMenuOpened}
                                openLeftMenuCallBack={() =>
                                    setIsMenuOpened(!isMenuOpened)
                                }
                                navCssClasses="topnav-navbar"
                            />
                        </Suspense>

                        <Suspense fallback={loading()}>
                            <Navbar {...props} isMenuOpened={isMenuOpened} />
                        </Suspense>

                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <RightSidebar {...props}>
                            <ThemeCustomizer />
                        </RightSidebar>
                    </Suspense>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => ({
    layout: store.layout,
}))(HorizontalLayout)
