import 'react'
import { store } from '../redux'
import { logout } from '../redux/actions'
import { pusher, history } from './'

export default () => {
    pusher.disconnect()
    store.dispatch(logout())
    history.push('/account/login')
}
