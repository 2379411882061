import { getChannelProducts } from '../'

export default ({ keyword }) => {
    const channelProducts = getChannelProducts()
    const competitorProducts = [] // placeholder

    const updatedList = []
    const m = {
        numProducts: 0,
        numSponsored: 0,
        topRank: undefined,
        bestProductId: undefined,
    }

    if (!keyword.list) return { ...keyword, ...m }

    for (const item of keyword.list) {
        let isCompetitor = false
        let matchedProduct = channelProducts.filter((p) => p.id == item.asin)[0]

        if (!matchedProduct) {
            matchedProduct = competitorProducts.filter(
                (p) => p.id == item.asin
            )[0]
            if (matchedProduct) isCompetitor = true
        }

        if (matchedProduct && !item.sponsored) m.numProducts++
        if (matchedProduct && item.sponsored) m.numSponsored++
        if (!m.topRank && matchedProduct && !item.sponsored) {
            m.topRank = item.rank
            m.bestProductId = matchedProduct.id
        }
        if (matchedProduct) item.model = matchedProduct.model

        // if (isCompetitor) console.log('found a competitor', item)

        updatedList.push({
            ...item,
            isMatch: isCompetitor ? false : PropTypes.boolean(matchedProduct),
            isCompetitor,
        })
    }

    return {
        ...keyword,
        ...m,
        list: updatedList,
    }
}
